import React, { useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { apisService } from '../../services/apiService';
import './signupStyles.css';

const VerifyOTP = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const email = state?.email || ''; // Email passed from the previous page
    const toast = useRef(null);

    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef([]);

    // Handle OTP input change
    const handleOtpChange = (e, index) => {
        const value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numbers
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Move to the next input box if the input is valid
        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    // Handle key events for OTP inputs
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index]) {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    // Handle "Next" button click
    const handleNextClick = async () => {
        const enteredOtp = otp.join('');

        if (enteredOtp.length !== 6) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter all 6 digits of the OTP.', life: 3000 });
            return;
        }

        setLoading(true);
        const payload = { email, code: parseInt(enteredOtp, 10) };

        try {
            const response = await apisService.verifyAccountApi(payload);

            toast.current.show({ severity: 'success', summary: 'Success', detail: 'OTP verified successfully.', life: 3000 });

            // Navigate to the "Create Password" page with email and xToken
            const xToken = response?.token;
            navigate('/createPassword', { state: { email, xToken } });
        } catch (error) {
            console.error('Error during OTP verification:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error?.response?.data?.message || 'OTP verification failed.',
                life: 3000,
            });
        } finally {
           
            setLoading(false);
        }
    };

    // Handle "Resend Code" link click
    const handleResendCode = async () => {
        setLoading(true);
        setOtp(new Array(6).fill(''));

        try {
            await apisService.reSendCodeApi(email, 'signup');

            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Verification code resent successfully.', life: 3000 });
        } catch (error) {
            console.error('Error resending code:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to resend verification code.',
                life: 3000,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="verify-container signup-main">
            <Toast ref={toast} />

            <div className="back-arrow">
                <Link to="/signup">
                    <i className="fa fa-arrow-left"></i>
                </Link>
            </div>

            <h2>Verify your number</h2>
            <p>
                Please enter the 6-digit verification code sent to <strong>{email}</strong>. The code is valid for 30 minutes.
            </p>

            <form className="signup-form" onSubmit={(e) => e.preventDefault()}>
                <span className="verification-code">Verification Code</span>

                <div className="form-group otp-input-group">
                    {otp.map((value, index) => (
                        <input
                            key={index}
                            type="text"
                            className="verification-input"
                            maxLength="1"
                            value={value}
                            onChange={(e) => handleOtpChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                            autoFocus={index === 0} // Auto-focus the first input box
                        />
                    ))}
                </div>

                <div style={{ fontSize: 13 }} className="resend-container">
                    <span>Didn't receive the verification code?</span>
                    <button
                        style={{ border: 'none', background: 'none', fontSize: 13 }}
                        type="button"
                        className="sendCode-btn"
                        onClick={handleResendCode}
                        disabled={loading}
                    >
                        Resend Code
                    </button>
                </div>

                <Button
                    label="Next"
                    className="btn-login"
                    onClick={handleNextClick}
                    loading={loading}
                />
            </form>
        </div>
    );
};

export default VerifyOTP;
