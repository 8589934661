import React from "react";
import "./footer.css";

const Footer = () => {

    return (
        <>
            <div className="footer-wrap">
                <footer className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <h5>Corporate</h5>
                                <ul>
                                    <li><a href="#">About Us</a></li>
                                    <li><a href="#">News</a></li>
                                    <li><a href="#">Terms</a></li>
                                    <li><a href="#">Privacy</a></li>
                                    <li><a href="#">Refferal</a></li>
                                    <li><a href="#">24/7 Support</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <h5>Learn</h5>
                                <ul>
                                    <li><a href="#">Buy Bitcoin</a></li>
                                    <li><a href="#">Buy BNB</a></li>
                                    <li><a href="#">Buy Ripple</a></li>
                                    <li><a href="#">Buy Dogecoin</a></li>
                                    <li><a href="#">Buy Ethereum</a></li>
                                    <li><a href="#">Buy Memecoins</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <h5>Crypto Prices</h5>
                                <ul>
                                    <li><a href="#">Bitcoin Price</a></li>
                                    <li><a href="#">BNB Price</a></li>
                                    <li><a href="#">Ripple Price</a></li>
                                    <li><a href="#">Dogecoin Price</a></li>
                                    <li><a href="#">Ethereum Price</a></li>
                                    <li><a href="#">Solana Memecoins</a></li>
                                </ul>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <h5>Community</h5>
                                <ul className="social-icons">
                                    <li><a href="#"><i className="fa fa-youtube"></i></a></li>
                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                    <li><a href="#"><i className="fa fa-telegram"></i></a></li>
                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                    
                                </ul>
                            </div>
                        </div>
                        </div>


                </footer>
                <div className="footer-bottom text-center">
                    <p>© Bitx 2024. Cookie Preferences.</p>
                </div>
            </div>
     
        </>
    )
}
export default Footer