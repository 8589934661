import React, { useEffect, useState } from 'react';
import './Dashboard.css'; // You will place your CSS in this file
import userIcon from '../../assets/images/profile-pic.jpg';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { apisService } from '../../services/apiService';
import axios from 'axios';
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import Binance from 'binance-api-node';

// const client = Binance();

const Dashboard = () => {

  const [selectedCategoryId, setSelectedCategoryId] = useState('1');
  const [filteredCryptoData, setFilteredCryptoData] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [announcementList, setAnnouncementList] = useState([]);
  const [apiData, setApiData] = useState([])
  const [kycStatus, setKycStatus] = useState('loading..')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userProfile, setUserProfile] = useState(null)
  const [userName, setUserName] = useState('')
  const [userId, setUserId] = useState('')
  const [activeCurrency, setActiveCurrency] = useState("USDT");
  const currencies = ["USDT", "FDUST", "TUSD", "BNB", "BTC"];
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokenAndCallAPI = async () => {
      const token = localStorage.getItem('ACCESS_TOKEN');
      console.log(token, "localStorage");
      const userName = localStorage.getItem('userName');
      const userId = localStorage.getItem('userId');
      setUserName(userName)
      setUserId(userId)

      if (token?.accessToken) {
      }
    };


    const fetchInitialData = async () => {
      await fetchTokenAndCallAPI();
      // Fetch daily stats initially
      getNewsApi();
      announcementHandle();
      setSelectedCategoryId("1")
      getUser();
      await getDailyStats();
    };
    fetchInitialData();
  }, []);
  useEffect(() => {
    // statFiletrs();
    let categoryData = [];
    switch (selectedCategoryId) {
      case '1':
        categoryData = apiData.topGainerData;
        break;
      case '2':
        categoryData = apiData.topGainerData;
        break;
      case '3':
        categoryData = apiData.highVolumeData;
        break;
      case '4':
        categoryData = apiData.newListingData;
        break;
      default:
        categoryData = [];
    }
    setFilteredCryptoData(categoryData);
  }, [selectedCategoryId]);

  const getUser = async () => {
    // setLoading(true)
    const res = await apisService.getUserProfile();
    setUserProfile(res?.user?.profilePhoto);
    setKycStatus(res?.user?.kycStatus)
    // setEmail(res?.user?.email);
    setFirstName(res?.user?.firstName);
    setLastName(res?.user?.lastName);
    // setLoading(false)
  };

  const statFiletrs = (apiData) => {
    let categoryData = [];
    switch (selectedCategoryId) {
      case '1':
        categoryData = apiData.hotGainerData;
        break;
      case '2':
        categoryData = apiData.topGainerData;
        break;
      case '3':
        categoryData = apiData.highVolumeData;
        break;
      case '4':
        categoryData = apiData.newListingData;
        break;
      default:
        categoryData = [];
    }
    setFilteredCryptoData(categoryData);
  }

  const handleCurrencyClick = async (currency) => {
    setActiveCurrency(currency);
    try {
      let token = localStorage.getItem("ACCESS_TOKEN");
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(
        `https://backend.bitxuae.com/binance/daily-stats?pairSymbol=${currency}`,
        // `http://ec2-13-215-249-1.ap-southeast-1.compute.amazonaws.com:3000/binance/daily-stats?pairSymbol=${currency}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiData(response.data);
      console.log(response.data, "binance get");
      statFiletrs(response?.data);
    } catch (err) {
      console.error('Error fetching data:', err);
    }

  };

  const formatNumber = (num) => parseFloat(num).toFixed(2);
  const formatPrice = (price) => `$${formatNumber(price)}`;
  const formatPercentage = (percentage) => `${formatNumber(percentage)}%`;

  const getNewsApi = async () => {
    const result = await apisService.getNewsList();
    // console.log(result, "news api response")
    setNewsList(result?.data)
  }

  const getDailyStats = async () => {
    try {
      let token = localStorage.getItem("ACCESS_TOKEN");
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(
        `https://backend.bitxuae.com/binance/daily-stats?pairSymbol=${activeCurrency}`,
        // `http://ec2-13-215-249-1.ap-southeast-1.compute.amazonaws.com:3000/binance/daily-stats?pairSymbol=${activeCurrency}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiData(response.data);
      console.log(response.data, "binance get");
      statFiletrs(response?.data);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }

  const announcementHandle = async () => {
    const result = await apisService.getAnnouncementList();
    setAnnouncementList(result?.data)
  }

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e.target.value);
  };

  const handleTabChange = (e) => {
    const newCategoryId = (e.index + 1).toString(); // Convert tab index to string (1, 2, 3)
    setSelectedCategoryId(newCategoryId);
  };
  const onRowClick = (rowData) => {
    // Navigate to the details page and pass the symbol as a parameter
    navigate(`/crypto`, { state: { symbol: rowData.symbol } });
  };



  const actionTemplate = () => {
    return <img src="https://img.icons8.com/material-outlined/24/000000/line-chart.png" alt="chart icon" />;
  };

  const lastPriceTemplate = (item) => {
    return formatNumber(item.lastPrice)
  }

  const prevPriceTemplate = (item) => {
    return formatPrice(item.prevClosePrice)
  }
  const onChangeCoin = async (e) => {
    debugger
    const value = e?.target.value;
    try {
      let token = localStorage.getItem("ACCESS_TOKEN");
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get(
        `https://backend.bitxuae.com/binance/daily-stats?pairSymbol=${activeCurrency}`,
        // `http://ec2-13-215-249-1.ap-southeast-1.compute.amazonaws.com:3000/binance/daily-stats?pairSymbol=${activeCurrency}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApiData(response.data);
      console.log(response.data, "binance get");
      statFiletrs(response?.data);
    } catch (err) {
      console.error('Error fetching data:', err);
    }
  }

  const priceChangePercentTemplate = (item) => {
    return <button className={item.priceChangePercent < 0 ? "negative-price" : 'positive-price'}> {formatPercentage(item.priceChangePercent)}</button>
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row">

          {/* <!-- Content --> */}
          <div className="content-wrapper col-sm-12 col-md-12">

            {/* <!-- User Profile Section --> ok */}
            <div className="user-section" style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className="user-info">
                <img style={{ width: 70, height: 70, borderRadius: 5 }} src={userProfile ? userProfile : userIcon} alt="user" />
                <p style={{ fontWeight: 'bolder' }}>{`${firstName || ''} ${lastName || ''}`} <span className="fa fa-pencil" style={{ paddingLeft: '30px' }}></span></p>
              </div>
              {/* // thek ha */}
              <div className="user-id">
                <p style={{ fontWeight: 'bold' }}>User ID</p>
                <p>{userId}</p>
                {/* <p>985709988 <span className="fa fa-copy" style={{ paddingLeft: '20px' }}></span></p> */}
              </div>
              <div className="user-verification">
                <p style={{ fontWeight: 'bold' }}>Identity Verification</p>
                <p style={{ color: 'orange' }}><span className="fa fa-info" style={{ paddingRight: '10px' }}></span>{kycStatus}</p>
              </div>
              <div className="user-type">
                <p style={{ fontWeight: 'bold' }}>User Type</p>
                <p>Personal</p>
              </div>
              <div className="user-security">
                <p style={{ fontWeight: 'bold' }}>Security</p>
                <p style={{ color: 'red' }}>Low</p>
              </div>
            </div>

            {/* <!-- Balance and Deposit Section mobile --> */}
            <div id="myCaousel" className="carousel slide d-sm-none" data-bs-ride="carousel">
              <div className="carousel-indicators">
                <button type="button" data-bs-target="#myCaousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#myCaousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#myCaousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img src={require('../../assets/images/banner1.jpg')} alt="user" />
                </div>
                <div className="carousel-item">
                  <img src={require('../../assets/images/banner2.jpg')} alt="user" />
                </div>
                <div className="carousel-item">
                  <img src={require('../../assets/images/banner3.jpg')} alt="user" />
                </div>
              </div>

              <button className="carousel-control-prev" type="button" data-bs-target="#myCaousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#myCaousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
            <div className="panel panel-default mobile" style={{ width: '100%' }}>
              <div className="panel-body" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <h4>Estimated Balance</h4>
                  <p><strong>0.00 BTC</strong> <span className="text-muted">= $0.00</span></p>
                  <p>Total PNL: $0.00 (0.00%)</p>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'start', gap: '20px' }}>
                  <a href="/"><button className="btn">Deposit</button></a>
                  <a href="/"><button className="btn">Withdraw</button></a>
                  <a href="/"><button className="btn">Transfer</button></a>
                </div>
              </div>
            </div>

            <div className="abcv" style={{ display: 'flex', justifyContent: 'space-between', gap: '30px', alignItems: 'center', marginBottom: '50px' }}>

              <div style={{ width: '60%', display: 'grid', alignItems: 'center', padding: '32px', border: '2px solid #18bfd7', backgroundColor: '#dffbff', borderRadius: '10px' }}>
                <p style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '25px' }}>You currently don't have assets. Deposit or buy crypto via the methods below</p>
                <div style={{ display: 'flex', gap: '20px' }}>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '30px', width: '50%' }}>
                <div style={{ width: '50%', border: '1px solid #e5e5e5', padding: '40px', borderRadius: '10px' }}>
                  <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Deposit 10 USDT</p>
                  <p style={{ paddingBottom: '30px' }}><span className="fa fa-ticket" style={{ paddingRight: '10px' }}></span>30 USDT</p>
                  <p><span className="fa fa-clock-o" style={{ paddingRight: '10px' }}></span>Pending</p>
                </div>
                <div style={{ width: '50%', border: '1px solid #e5e5e5', padding: '40px', borderRadius: '10px' }}>
                  <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Deposit 30 USDT</p>
                  <p style={{ paddingBottom: '30px' }}><span className="fa fa-ticket" style={{ paddingRight: '10px' }}></span>50 USDT</p>
                  <p><span className="fa fa-clock-o" style={{ paddingRight: '10px' }}></span>Pending</p>
                </div>
              </div>

            </div>

            {/* <!-- Balance and Deposit Section --> */}
            <div className="panel panel-default desktop" style={{ width: '100%' }}>
              <div className="panel-body" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <h4>Estimated Balance</h4>
                  <p><strong>0.00 BTC</strong> <span className="text-muted">= $0.00</span></p>
                  <p>Total PNL: $0.00 (0.00%)</p>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'start', gap: '20px' }}>
                  <a href="/"><button className="btn">Deposit</button></a>
                  <a href="/"><button className="btn">Withdraw</button></a>
                  <a href="/"><button className="btn">Transfer</button></a>
                </div>
              </div>
            </div>

            <div className="tab-content" style={{ marginBottom: '20px', display: 'none' }}>
              <div id="all" className="tab-pane active">
                <div className="table-responsive">
                </div>
              </div>

              <div id="hot-coins" className="tab-pane">
                <p>Hot coins tab content.</p>
              </div>
              <div id="recent-transactions" className="tab-pane">
                <p>Recent transactions tab content.</p>
              </div>
            </div>

            <div className="tabs-main-cont">
              <div>
                <div className="search-container" style={{ marginBottom: '1rem' }}>
                  <input
                    type="text"
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Search coins..."
                    className="p-inputtext p-component"
                    // style={{
                    //   width: '100%',
                    //   padding: '10px',
                    //   fontSize: '14px',
                    //   borderRadius: '5px',
                    //   border: '1px solid #ccc',
                    // }}
                  />
                </div>
                <TabView activeIndex={parseInt(selectedCategoryId) - 1} onTabChange={handleTabChange}>
                  {/* Hot Tab */}
                  <TabPanel header="Hot">
                    <div>
                      {/* Horizontal list */}
                      <div className='horizontal-list'>
                        {currencies.map((currency) => (
                          <div
                            key={currency}
                            onClick={() => handleCurrencyClick(currency)}
                            style={{
                              margin: "0 10px",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius: 5,
                              background: activeCurrency === currency ? "#e2ecf4" : "",
                              fontWeight: activeCurrency === currency ? "bold" : "normal",
                              color: activeCurrency === currency ? "#007ad9" : "black",
                              // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                            }}
                          >
                            {currency}
                          </div>
                        ))}
                      </div>
                      <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)} // Add onRowClick for navigation
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}

                      >
                        <Column
                          header="Name"
                          body={(rowData) => (
                            <div>
                              <img
                                src={rowData.logo}
                                alt={rowData.name}
                                style={{ width: "24px", marginRight: "8px" }}
                              />
                              {rowData?.symbol}
                            </div>
                          )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                        {/* <Column header="Action" body={actionTemplate} /> */}
                      </DataTable>
                    </div>
                  </TabPanel>

                  {/* Top Gainer Tab */}
                  <TabPanel header="Top Gainer">
                    <div>
                      {/* Horizontal list */}
                      <div className='horizontal-list'>
                        {currencies.map((currency) => (
                          <div
                            key={currency}
                            onClick={() => handleCurrencyClick(currency)}
                            style={{
                              margin: "0 10px",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius: 5,
                              background: activeCurrency === currency ? "#e2ecf4" : "",
                              fontWeight: activeCurrency === currency ? "bold" : "normal",
                              color: activeCurrency === currency ? "#007ad9" : "black",
                              // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                            }}
                          >
                            {currency}
                          </div>
                        ))}
                      </div>
                      <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)}
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}
                      >
                        <Column
                          header="Name"
                          body={(rowData) => (
                            <div>
                              <img
                                src={rowData.logo}
                                alt={rowData.name}
                                style={{ width: "24px", marginRight: "8px" }}
                              />
                              {rowData.symbol}
                            </div>
                          )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                      </DataTable>
                    </div>
                  </TabPanel>

                  {/* High Volume Tab */}
                  <TabPanel header="High Volume">
                    <div>
                      {/* Horizontal list */}
                      <div className='horizontal-list'>
                        {currencies.map((currency) => (
                          <div
                            key={currency}
                            onClick={() => handleCurrencyClick(currency)}
                            style={{
                              margin: "0 10px",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius: 5,
                              background: activeCurrency === currency ? "#e2ecf4" : "",
                              fontWeight: activeCurrency === currency ? "bold" : "normal",
                              color: activeCurrency === currency ? "#007ad9" : "black",
                              // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                            }}
                          >
                            {currency}
                          </div>
                        ))}
                      </div>
                      <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)}
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}
                      >
                        <Column
                          header="Name"
                          body={(rowData) => (
                            <div>
                              <img
                                src={rowData.logo}
                                alt={rowData.name}
                                style={{ width: "24px", marginRight: "8px" }}
                              />
                              {rowData.symbol}
                            </div>
                          )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                      </DataTable>
                    </div>
                  </TabPanel>

                  {/* New Listing Tab */}
                  <TabPanel header="New Listing">
                    <div>
                      {/* Horizontal list */}
                      <div className='horizontal-list'>
                        {currencies.map((currency) => (
                          <div
                            key={currency}
                            onClick={() => handleCurrencyClick(currency)}
                            style={{
                              margin: "0 10px",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius: 5,
                              background: activeCurrency === currency ? "#e2ecf4" : "",
                              fontWeight: activeCurrency === currency ? "bold" : "normal",
                              color: activeCurrency === currency ? "#007ad9" : "black",
                              // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                            }}
                          >
                            {currency}
                          </div>
                        ))}
                      </div>
                      <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)}
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}
                      >
                        <Column
                          header="Name"
                          body={(rowData) => (
                            <div>
                              <img
                                src={rowData.logo}
                                alt={rowData.name}
                                style={{ width: "24px", marginRight: "8px" }}
                              />
                              {rowData.symbol}
                            </div>
                          )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                      </DataTable>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>



            <div className="graph-section row" style={{ margin: '0' }}>
              <div style={{ height: 'auto' }} className="graph col-sm-8 pull-left">
                <div className="tabs-main-cont">
                  <TabView>
                    <TabPanel header="Latest News" leftIcon="pi pi-calendar">
                      {newsList.map((news, index) => (
                        <div className="row" key={index}>
                          <div className="col-sm-3">
                            <img src={news?.image} alt="News Picture"
                              className="img-responsive"
                              style={{ width: '112px', borderRadius: '10px', display: 'block', margin: '0 auto 15px' }}
                            />
                          </div>
                          <div className="col-sm-9">
                            <p>{news.description}</p>
                          </div>
                        </div>
                      ))}
                    </TabPanel>

                    <TabPanel header="Announcements" leftIcon="pi pi-bell">
                      <ul>
                        {announcementList.map((announcement, index) => (
                          <li key={index}>
                            {announcement.description} <span className="date-txt">{moment(announcement?.date).format("YYYY-MM-DD")}</span>
                          </li>
                        ))}
                      </ul>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
              <div style={{ height: 'auto', paddingRight: '0' }} className="ad-baner col-sm-4 pull-right">
                {/* Add graph or chart data here */}
                <img alt="" src={require('../../assets/images/adertise-banner.jpg')} className="img-responsive"
                />
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
