import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
import "./graph.css";
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import axios from "axios";

const CryptoDashboard = () => {
    const location = useLocation();
    const { symbol } = location.state || { symbol: "BTCUSDT" }; // Default to BTCUSDT
    const tradingViewContainerRef = useRef(null);

    const [orderBook, setOrderBook] = useState({ bids: [], asks: [] });
    const [marketData, setMarketsData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCrypto, setSelectedCrypto] = useState(symbol);
    const [cryptoList, setCryptoList] = useState([]);

    const openOrders = [
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            Price: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            limit: " Limit / Buy",
            Amount: '0.4654 / 0.4654',
            Price: '0.4654 / 0.4654',
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Price: '0.4654 / 0.4654',
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Price: '0.4654 / 0.4654',
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Price: '0.4654 / 0.4654',
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            Price: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            Price: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            Price: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Status: 'Filled',
        },
        {
            name: "BTC/USDT",
            Amount: '0.4654 / 0.4654',
            limit: " Limit / Buy",
            Price: '0.4654 / 0.4654',
            Status: 'Filled',
        },
    ]
    useEffect(() => {
        // Configure TradingView Widget
        if (tradingViewContainerRef.current && tradingViewContainerRef.current.firstChild) {
            tradingViewContainerRef.current.innerHTML = "";
        }
        //   "symbol": "BINANCE:${symbol}", // Use dynamic symbol
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${selectedCrypto}",
          "interval": "1D",
          "timezone": "Etc/UTC",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "allow_symbol_change": false,
          "calendar": false,
          "height": 500
        }`;
        console.log("before checking");
        tradingViewContainerRef.current.appendChild(script);

        // Attach symbol monitoring
        const observer = monitorSymbolChange(tradingViewContainerRef.current);
        console.log("observer", observer);
        // Cleanup
        return () => {
            if (tradingViewContainerRef.current) {
                observer.disconnect();
                tradingViewContainerRef.current.innerHTML = "";
            }
        };
    }, [selectedCrypto]);
    // }, [symbol]);

    const monitorSymbolChange = (container) => {
        console.log("in symbol channge");
        const observer = new MutationObserver(() => {
            const iframe = container.querySelector("iframe");
            console.log("iframeeee", iframe);
            if (iframe) {
                const src = iframe.getAttribute("src");
                console.log("src is ", src);
                const match = src.match(/symbol=([^&]*)/);
                if (match && match[1]) {
                    const newSymbol = match[1];
                    console.log("Symbol changed:", newSymbol);

                    // Trigger callback with the new symbol
                    // onSymbolChange(newSymbol.replace("BINANCE:", ""));
                }
            }
        });

        // Start observing
        observer.observe(container, { childList: true, subtree: true });

        return observer; // Return the observer to allow disconnecting later
    };




    // widget.onChartReady(() => {
    //     const chart = widget.chart();
    //     chart.onSymbolChange().subscribe(null, (newSymbol) => {
    //         console.log('Symbol changed:', newSymbol);

    //         // Call Binance API based on the new symbol
    //         fetchBinanceData(newSymbol);
    //     });
    // });

    useEffect(() => {
        let isMounted = true; // Track if the component is still mounted

        const fetchOrderBookData = async () => {
            const marketLimit = "50";
            const marketsTradesRes = `https://api.binance.com/api/v3/trades?symbol=${selectedCrypto}&limit=${marketLimit}`;
            // const marketsTradesRes = `https://api.binance.com/api/v3/trades?symbol=${symbol}&limit=${marketLimit}`;

            try {
                const markets = await fetch(marketsTradesRes);
                const responseMarkets = await markets.json();
                if (isMounted) {
                    setMarketsData(responseMarkets);
                    // console.log("response markets", responseMarkets);
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching Binance order book data:", error);
                }
            }
        };

        fetchOrderBookData();

        // Cleanup function
        return () => {
            isMounted = false; // Prevent state updates on unmounted components
        };
    }, [])

    useEffect(() => {
        let isMounted = true; // Track if the component is still mounted

        const fetchOrderBookData = async () => {
            const limit = 1000;
            const marketLimit = "50";
            const url = `https://api.binance.com/api/v3/depth?symbol=${selectedCrypto}&limit=${limit}`;
            // const url = `https://api.binance.com/api/v3/depth?symbol=${symbol}&limit=${limit}`;
            const marketsTradesRes = `https://api.binance.com/api/v3/trades?symbol=${selectedCrypto}&limit=${marketLimit}`;
            // const marketsTradesRes = `https://api.binance.com/api/v3/trades?symbol=${symbol}&limit=${marketLimit}`;

            try {
                const markets = await fetch(marketsTradesRes);
                const responseMarkets = await markets.json();

                const response = await fetch(url);
                const data = await response.json();

                if (isMounted) {
                    setMarketsData(responseMarkets);
                    // console.log("response markets", responseMarkets);
                    setOrderBook({
                        bids: data.bids.map(([price, quantity]) => ({
                            price: parseFloat(price).toFixed(2),
                            quantity: parseFloat(quantity).toFixed(4),
                        })),
                        asks: data.asks.map(([price, quantity]) => ({
                            price: parseFloat(price).toFixed(2),
                            quantity: parseFloat(quantity).toFixed(4),
                        })),
                    });
                }
            } catch (error) {
                if (isMounted) {
                    console.error("Error fetching Binance order book data:", error);
                }
            }
        };

        fetchOrderBookData();
        const interval = setInterval(fetchOrderBookData, 5000);

        // Cleanup function
        return () => {
            isMounted = false; // Prevent state updates on unmounted components
            clearInterval(interval); // Clear the interval to avoid memory leaks
        };
    }, [symbol, selectedCrypto]);
    const timeTemplate = (time) => {
        const timestamp = time; // Milliseconds
        const date = new Date(timestamp); // Convert to Date object

        // Extract hours, minutes, and seconds
        const hours = date.getHours().toString().padStart(2, '0'); // Ensures 2 digits
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        // Format the time
        const formattedTime = `${hours}:${minutes}:${seconds}`;
        return formattedTime
    }
    const fetchCryptoList = async (query) => {
        try {
            let token = localStorage.getItem("ACCESS_TOKEN");
            if (!token) {
                throw new Error("No token found");
            }
            const response = await axios.get(
                `https://backend.bitxuae.com/binance/daily-stats?search=${query}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // const filtered = response.data.filter((item) =>
            //     item.symbol.toLowerCase().includes(query.toLowerCase())
            // );
            setCryptoList(response.data.slice(0, 10));
        } catch (error) {
            console.error('Failed to fetch crypto list:', error);
        }
    };

    const handleCryptoSelect = (symbol) => {
        setSelectedCrypto(symbol);
        setModalVisible(false);
    };

    // useEffect(async () => {
    //     debugger
    //     // Fetch Order Book Data
    //     // const marketLimit = 500;
    //     // const marketsTradesRes = `https://api.binance.com/api/v3/trades?symbol=BTCUSDT&limit=${marketLimit}`;
    //     // const responseMarkets = await fetch(marketsTradesRes);
    //     const fetchOrderBookData = async () => {
    //         const limit = 1000;
    //         const marketLimit = "500";
    //         const url = `https://api.binance.com/api/v3/depth?symbol=${symbol}&limit=${limit}`;
    //         const marketsTradesRes = `https://api.binance.com/api/v3/trades?symbol=BTCUSDT&limit=${marketLimit}`;
    //         const markets = await fetch(marketsTradesRes);
    //         const responseMarkets = await markets.json();
    //         try {
    //             const response = await fetch(url);
    //             const data = await response.json();
    //             setMarketsData(responseMarkets)
    //             console.log("response markets", responseMarkets);
    //             setOrderBook({
    //                 bids: data.bids.map(([price, quantity]) => ({
    //                     price: parseFloat(price).toFixed(2),
    //                     quantity: parseFloat(quantity).toFixed(4),
    //                 })),
    //                 asks: data.asks.map(([price, quantity]) => ({
    //                     price: parseFloat(price).toFixed(2),
    //                     quantity: parseFloat(quantity).toFixed(4),
    //                 })),
    //             });
    //         } catch (error) {
    //             console.error("Error fetching Binance order book data:", error);
    //         }
    //     };

    //     fetchOrderBookData();
    //     const interval = setInterval(fetchOrderBookData, 5000);

    //     return () => clearInterval(interval);
    // }, [symbol]);

    return (
        <>
            <div className="row order-book-data">
                <div className="col-12">
                    {/* <div className="crypto-selector">
                        <div
                            className="crypto-input"
                            onClick={() => setModalVisible(true)}
                        >
                            {selectedCrypto}
                        </div>
                        <Dialog
                            visible={modalVisible}
                            onHide={() => setModalVisible(false)}
                            header="Select Coins"
                            className="crypto-modal"
                        >
                            <div className="p-inputgroup">
                                <InputText
                                    value={searchTerm}
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        fetchCryptoList(e.target.value);
                                    }}
                                    placeholder="Search crypto..."
                                    style={{ width: '100%', padding: '10px', paddingLeft: 20 }}
                                />
                            </div>

                            <div className="crypto-list">
                                {cryptoList.length > 0 ? (
                                    cryptoList.map((crypto) => (
                                        <div
                                            key={crypto.symbol}
                                            className="crypto-item"
                                            onClick={() => handleCryptoSelect(crypto.symbol)}
                                        >
                                            {crypto.symbol}
                                        </div>
                                    ))
                                ) : (
                                    <p className="no-results">No results found</p>
                                )}
                            </div>
                        </Dialog>
                    </div> */}
                </div>
                {/* <div className="col-lg- col-md-12"> */}
                <div className="col-lg-6 order-lg-2 chart-data">
                    <div className="coin-view">
                        <div className="crypto-selector">
                            <div
                                className="crypto-input col-lg-12"
                                onClick={() => setModalVisible(true)}
                            >
                                {selectedCrypto}
                            </div>
                            <Dialog
                                visible={modalVisible}
                                onHide={() => setModalVisible(false)}
                                header="Select Coins"
                                className="crypto-modal"
                            >
                                <div className="p-inputgroup">
                                    <InputText
                                        value={searchTerm}
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                            fetchCryptoList(e.target.value);
                                        }}
                                        placeholder="Search crypto..."
                                        style={{ width: '100%', padding: '10px', paddingLeft: 20 }}
                                    />
                                </div>

                                <div className="crypto-list">
                                    {cryptoList.length > 0 ? (
                                        cryptoList.map((crypto) => (
                                            <div
                                                key={crypto.symbol}
                                                className="crypto-item"
                                                onClick={() => handleCryptoSelect(crypto.symbol)}
                                            >
                                                {crypto.symbol}
                                            </div>
                                        ))
                                    ) : (
                                        <p className="no-results">No results found</p>
                                    )}
                                </div>
                            </Dialog>
                        </div>
                    </div>
                    <div
                        className="tradingview-widget-container"
                        ref={tradingViewContainerRef}
                    >
                        {/* <div
                            className="tradingview-widget-container__widget"
                        >
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-3 order-lg-1 col-md-6">
                    <div className="border-box">
                        <TabView>
                            <TabPanel header="Order Book">
                                {/* <div className="order-book-border">
                                    <span className="order-book">Order Book</span>
                                </div> */}
                                <table className="order-book-table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Amount</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderBook.asks.map((ask, index) => (
                                            <tr key={index}>
                                                <td className="ask">{ask.price}</td>
                                                <td>{ask.quantity}</td>
                                                <td>{(ask.price * ask.quantity).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <table className="order-book-table">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Amount</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderBook.bids.map((bid, index) => (
                                            <tr key={index}>
                                                <td className="bid">{bid.price}</td>
                                                <td>{bid.quantity}</td>
                                                <td>{(bid.price * bid.quantity).toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel header="Market Trades">
                                <table className="market-trade">
                                    <thead>
                                        <tr>
                                            <th>Price</th>
                                            <th>Amount</th>
                                            <th>Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {marketData?.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ color: item.isBuyerMaker == true ? "#07bc92" : "#f67e70" }}>{item.price}</td>
                                                <td>{item.qty}</td>
                                                <td>{timeTemplate(item.time)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </TabPanel>

                        </TabView>
                        {/* <div className="order-book-border">
                            <span className="order-book">Order Book</span>
                        </div>
                        <table className="order-book-table">
                            <thead>
                                <tr>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderBook.asks.map((ask, index) => (
                                    <tr key={index}>
                                        <td className="ask">{ask.price}</td>
                                        <td>{ask.quantity}</td>
                                        <td>{(ask.price * ask.quantity).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <table className="order-book-table">
                            <thead>
                                <tr>
                                    <th>Price</th>
                                    <th>Amount</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderBook.bids.map((bid, index) => (
                                    <tr key={index}>
                                        <td className="bid">{bid.price}</td>
                                        <td>{bid.quantity}</td>
                                        <td>{(bid.price * bid.quantity).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> */}
                    </div>
                </div>
                <div className="col-lg-3 order-lg-3">
                    <div class="buy-sell">

                        <div id="sd-btns-main">
                            <button id="buyBtn" onclick="showBuyTab()">Buy</button>
                            <button id="sellBtn" onclick="showSellTab()">Sell</button>
                        </div>

                        <ul id="rgt-inr-itms" role="tablist">
                            <li class="active"><a data-toggle="tab" href="#spot" role="tab" aria-controls="spot" aria-selected="true" aria-expanded="true">Spot</a></li>
                            <li class=""><a data-toggle="tab" href="#cross" role="tab" aria-controls="cross" aria-selected="false" aria-expanded="false">Cross</a></li>
                            <li class=""><a data-toggle="tab" href="#isolated" role="tab" aria-controls="isolated" aria-selected="false" aria-expanded="false">Isolated</a></li>
                        </ul>

                        <div class="tab-content" id="rgt-tabs-content">
                            <div class="tab-pane active in" id="spot" role="tabpanel" aria-labelledby="spot-tab">
                                <div id="buy-form" class="buy-sell-form" style={{ display: 'block' }}>
                                    <div className="limit-market">
                                        <button>Limit</button>
                                        <button>Market</button>
                                    </div>

                                    <div class="row-data">
                                        <span>Price</span>
                                        {/* <input type="text" class="form-control" id="price" placeholder="59000" value="59000"> */}
                                        <span class="input-group-addon">USDT</span>
                                    </div>
                                    <div class="row-data">
                                        <span>Amount</span>
                                        {/* <input type="text" class="form-control" id="amount" placeholder="BTC"> */}
                                        <span class="input-group-addon">BTC</span>

                                    </div>

                                    <div class="middle-btns-cont">
                                        <button>20%</button>
                                        <button className="active">40%</button>
                                        <button>60%</button>
                                        <button>80%</button>
                                        <button>100%</button>
                                    </div>

                                    <div class="row-data">
                                        <span>Total</span>

                                        {/* <input type="text" class="form-control" id="total" placeholder="USDT"> */}
                                        <span class="input-group-addon">USDT</span>

                                    </div>

                                    <div className="checkbox-tp">
                                        <Checkbox
                                            inputId="remember"
                                        />
                                        {/* <input type="checkbox" class="form-check-input" id="tp-sl"> */}
                                        <label for="tp-sl">TP/SL</label>
                                    </div>

                                    <div class="row-data row-data-bg">
                                        <span>Avbl:</span> <span>0.00000000 USDT <i className="fa-solid fa-circle-plus"></i></span>
                                    </div>
                                    <div class="row-data row-data-bg">
                                        <span>Max Buy:</span> <span>0 BTC</span>
                                    </div>
                                    <div class="row-data row-data-bg">
                                        <span>Est. Fee </span>
                                    </div>



                                    <a className="buy-btc" href="/buy-graph.html">Buy BTC</a>

                                </div>

                                {/* <!-- Sell Form --> */}
                                <div id="sell-form" class="buy-sell-form" style={{ display: 'none' }}>
                                    <div class="d-flex mb-3">
                                        <button class="btn btn-light active" >Limit</button>
                                        <button class="btn btn-light" >Market</button>
                                    </div>

                                    <div class="mb-3">
                                        <label for="price-sell" class="form-label">Price</label>
                                        <div class="input-group">
                                            {/* <input type="text" class="form-control" id="price-sell" placeholder="59000" value="59000"> */}
                                            <span class="input-group-addon">USDT</span>
                                        </div>
                                    </div>

                                    <div class="mb-3">
                                        <label for="amount-sell" class="form-label">Amount</label>
                                        <div class="input-group">
                                            {/* <input type="text" class="form-control" id="amount-sell" placeholder="BTC"> */}
                                            <span class="input-group-addon">BTC</span>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-between mb-4 middle-btns-cont" style={{ padding: '0 15px' }}>
                                        <button class="btn btn-light" style={{ width: '19%' }}>20%</button>
                                        <button class="btn btn-primary" style={{ width: '19%' }}>40%</button>
                                        <button class="btn btn-light" style={{ width: '19%' }}>60%</button>
                                        <button class="btn btn-light" style={{ width: '19%' }}>80%</button>
                                        <button class="btn btn-light" style={{ width: '19%' }}>100%</button>
                                    </div>

                                    <div class="mb-3">
                                        <label for="total-sell" class="form-label">Total</label>
                                        <div class="input-group">
                                            {/* <input type="text" class="form-control" id="total-sell" placeholder="USDT"> */}
                                            <span class="input-group-addon">USDT</span>
                                        </div>
                                    </div>

                                    <div class="mb-43 form-check">
                                        {/* <input type="checkbox" class="form-check-input" id="tp-sl-sell"> */}
                                        <label class="form-check-label" for="tp-sl-sell">TP/SL</label>
                                    </div>

                                    <div class="mb-3 btm-txt-cont">
                                        <p>Avbl: <span>0.00000000 USDT <i className="fa-solid fa-circle-plus"></i></span></p>
                                        <p>Max Buy: <span>0 BTC</span> </p>
                                        <p>Est. Fee </p>

                                    </div>

                                    <button class="btn btn-danger w-100">Sell BTC</button>
                                </div>
                            </div>

                            {/* <!-- Cross Tab Content --> */}
                            <div class="tab-pane" id="cross" role="tabpanel" aria-labelledby="cross-tab">
                                <h5>Cross - Buy Form</h5>
                                <p>This is the cross margin trading form. Data can be similar to the spot form but with cross margin settings.</p>
                            </div>

                            {/* <!-- Isolated Tab Content --> */}
                            <div class="tab-pane" id="isolated" role="tabpanel" aria-labelledby="isolated-tab">
                                <h5>Isolated - Buy Form</h5>
                                <p>This is the isolated margin trading form. Data can be similar to the spot form but with isolated margin settings.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-12">
                    <TabView>
                        <TabPanel header="Open Orders">
                            <DataTable
                                value={openOrders}
                                // stripedRows
                                scrollable
                                scrollHeight="60vh"
                                rowClassName={() => "hoverable-row"} // Add a class for each row

                            >
                                <Column header="Name" field="name" />
                                <Column header="limit" field="limit" />
                                <Column header="Amount" field="Amount" />
                                <Column header="Price" field="Price" />
                                <Column header="Status" field="Status" />
                                {/* <Column header="Action" body={actionTemplate} /> */}
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Order History">
                            <DataTable
                                value={openOrders}
                                // stripedRows
                                scrollable
                                scrollHeight="60vh"
                                rowClassName={() => "hoverable-row"} // Add a class for each row

                            >
                                <Column header="Name" field="name" />
                                <Column header="limit" field="limit" />
                                <Column header="Amount" field="Amount" />
                                <Column header="Price" field="Price" />
                                <Column header="Status" field="Status" />
                                {/* <Column header="Action" body={actionTemplate} /> */}
                            </DataTable>
                        </TabPanel>
                        <TabPanel header="Trades History">
                            <DataTable
                                value={openOrders}
                                // stripedRows
                                scrollable
                                scrollHeight="60vh"
                                rowClassName={() => "hoverable-row"} // Add a class for each row

                            >
                                <Column header="Name" field="name" />
                                <Column header="limit" field="limit" />
                                <Column header="Amount" field="Amount" />
                                <Column header="Price" field="Price" />
                                <Column header="Status" field="Status" />
                                {/* <Column header="Action" body={actionTemplate} /> */}
                            </DataTable>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </>
    );
};

export default CryptoDashboard;
