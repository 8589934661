import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Transactions from './pages/Transactions/Transactions';
import Trade from './pages/Trade/Trade';
import SpotOrders from './pages/SpotOrders/SpotOrders';
import Referral from './pages/Referral/Referral';
import Overview from './pages/OverView/Overview';
import MemeCoins from './pages/MemeCoins/MemeCoins';
import Marketing from './pages/Marketing/Marketing';
import Profile from './pages/Profile/Profile';
import SidebarComponent from './components/sidebar';
import TopNavBar from './pages/navigationBar/navBar';
import SettingsScreen from './pages/settingScreen/settings';
import IdentityVerification from './pages/identity/identity';
import OrdersScreen from './pages/orders/orders';
import AssetsScreen from './pages/assetsScreen/assetsScreen';
import LoginScreen from './pages/signupPages/login';
import Signup from './pages/signupPages/signUp';
import VerifyOTP from './pages/signupPages/verifyOTP';
import CreatePassword from './pages/signupPages/createPassword';
import KYCOne from './pages/signupPages/kycOneAuth';
import KycTwoAuth from './pages/signupPages/kycTwoAuth';
import ForgotPassword from './pages/signupPages/forgetPassword';
import VerifyEmail from './pages/signupPages/verifyEmail';
import NewPassword from './pages/signupPages/newPassword';
import CryptoDashboard from './pages/graph/graph';
import '../src/App.css'
import BuySell from './pages/buySell/buySell';
import Footer from './components/Footer';
// imoprt LoginScreen
// import Login from './pages/signupPages/Login';
// import Signup from './pages/signupPages/Signup';

// Layout component for the Dashboard Flow
const DashboardLayout = ({ children, logout }) => {
  return (
    <div className="d-flex">
      <SidebarComponent logout={logout} />
      <div className="wrapper">
        <TopNavBar />
        <div className="p-2">{children}</div>
        <Footer />
      </div>
      
    </div>
  );
};

// Layout component for the Auth Flow (No Sidebar/TopNavBar)
const AuthLayout = ({ children }) => {
  return <div className="auth-container">{children}</div>;
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check for token in localStorage on initial load
    const token = localStorage.getItem('ACCESS_TOKEN');
    setIsAuthenticated(!!token);
  }, []);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };
  const logout = () => {
    // localStorage.clear(); // Clear all items from localStorage
    setIsAuthenticated(false); // Update authentication state
  };

  return (
    <Router>
      <Routes>
        {/* Redirect to the appropriate route based on authentication status */}
        {isAuthenticated ? (
          // Protected Dashboard Routes
          <Route
            path="*"
            element={
              <DashboardLayout logout={logout}>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dasboard" element={<Dashboard />} />
                  <Route path="/transactions" element={<Transactions />} />
                  <Route path="/trade" element={<Trade />} />
                  <Route path="/spot-orders" element={<SpotOrders />} />
                  <Route path="/referral" element={<Referral />} />
                  <Route path="/overview" element={<Overview />} />
                  <Route path="/meme-coins" element={<MemeCoins />} />
                  <Route path="/marketing" element={<Marketing />} />
                  <Route path="/buySell" element={<BuySell />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/settings" element={<SettingsScreen />} />
                  <Route path="/identity" element={<IdentityVerification />} />
                  <Route path="/orders" element={<OrdersScreen />} />
                  <Route path="/crypto" element={<CryptoDashboard />} />
                  <Route path="/assets" element={<AssetsScreen />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </DashboardLayout>
            }
          />
        ) : (
          // Auth Routes
          <Route
            path="*"
            element={
              <AuthLayout>
                <Routes>
                  <Route path="/login" element={<LoginScreen onLogin={handleLogin} />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/Verifyotp" element={<VerifyOTP />} />
                  <Route path="/createPassword" element={<CreatePassword />} />
                  <Route path="/kycAuthOne" element={<KYCOne />} />
                  <Route path="/kycAuthTwo" element={<KycTwoAuth />} />
                  <Route path="/forgetPassword" element={<ForgotPassword />} />
                  <Route path="/verifyEmail" element={<VerifyEmail />} />
                  <Route path="/newPassword" element={<NewPassword />} />
                  {/* <Route path="/signup" element={<Signup />} /> */}
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </AuthLayout>
            }
          />
        )}
      </Routes>
    </Router>
  );
}

export default App;
