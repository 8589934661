import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import "./buySell.css";
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

// Load your Stripe publishable key
const stripePromise = loadStripe("pk_test_51PXRjCI2skuUgT551uczaGX278oiQ5WSm8kOjuvgYigtBZJDNG1nI3dOEQrgfnyzBw08aTtd167LlHSMs72Cqz4P00cGB4diN4");
// import "./App.css"; // Include your CSS styles here
const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        // Create a payment method
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
        });

        if (error) {
            console.error("Payment error:", error.message);
            setError(error.message);
            setLoading(false);
        } else {
            console.log("PaymentMethod:", paymentMethod);
            setPaymentSuccess(true);
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: "400px", margin: "auto" }}>
            <h3>Complete Payment</h3>
            <CardElement
                options={{
                    style: {
                        base: {
                            fontSize: "16px",
                            color: "#424770",
                            "::placeholder": {
                                color: "#aab7c4",
                            },
                        },
                        invalid: {
                            color: "#9e2146",
                        },
                    },
                }}
            />
            <button
                type="submit"
                disabled={!stripe || loading}
                style={{
                    marginTop: "20px",
                    backgroundColor: "#5469d4",
                    color: "white",
                    padding: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                }}
            >
                {loading ? "Processing..." : "Pay"}
            </button>
            {error && <div style={{ color: "red", marginTop: "10px" }}>{error}</div>}
            {paymentSuccess && (
                <div style={{ color: "green", marginTop: "10px" }}>
                    Payment Successful!
                </div>
            )}
        </form>
    );
};

const BuySell = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [withdrawForm, setWithdrawForm] = useState({
        address: "",
        network: null,
        amount: "",
    });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const networks = [
        { label: "Bitcoin", value: "bitcoin" },
        { label: "Solana", value: "solana" },
    ];

    const handleWithdraw = () => {
        if (!withdrawForm.address || !withdrawForm.network || !withdrawForm.amount) {
            alert("Please fill out all fields.");
            return;
        }
        alert(`Withdrawal initiated for ${withdrawForm.amount} BTC.`);
    };

    const openModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);

    return (
        <div className="container">
            {/* Main Content */}
            <div className="row">
                <div className="col-md-12">
                    <TabView className="buy-sell-tabs" activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                        <TabPanel header="Stripe">
                            <Elements stripe={stripePromise}>
                                <CheckoutForm />
                            </Elements>
                        </TabPanel>
                        <TabPanel header="Buy & Sell">
                            <h1 className="btm-title-bs">Buy Crypto Instantly</h1>
                            <div className="row">
                                {/* Hot Cryptos */}
                                <div className="col-md-6">
                                    <div className="crypto-list">
                                        <h5 className="mb-4">Hot Cryptos</h5>
                                        <ul className="list-group">
                                            <li className="d-flex justify-content-between align-items-center">
                                                <span><img className="crypto-icon"
                                                    src="https://cryptologos.cc/logos/binance-coin-bnb-logo.png"
                                                    alt="BNB" width="25" /> BNB</span>
                                                <span>$539.10</span>
                                                <span className="text-success">+1.83%</span>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                                <span><img className="crypto-icon"
                                                    src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
                                                    alt="BTC" width="25" /> BTC</span>
                                                <span>$57,941.27</span>
                                                <span className="text-success">+0.96%</span>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                                <span><img className="crypto-icon"
                                                    src="https://cryptologos.cc/logos/ethereum-eth-logo.png"
                                                    alt="ETH" width="25" /> ETH</span>
                                                <span>$2,349.90</span>
                                                <span className="text-success">+0.26%</span>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                                <span><img className="crypto-icon"
                                                    src="https://cryptologos.cc/logos/dogecoin-doge-logo.png"
                                                    alt="DOGE" width="25" /> DOGE</span>
                                                <span>$0.0010515</span>
                                                <span className="text-success">+1.06%</span>
                                            </li>
                                            <li className="d-flex justify-content-between align-items-center">
                                                <span><img className="crypto-icon"
                                                    src="https://cryptologos.cc/logos/worldcoin-wld-logo.png"
                                                    alt="WLD" width="25" /> WLD</span>
                                                <span>$1.56</span>
                                                <span className="text-success">+12.16%</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Buy Form */}
                                <div className="col-md-6">
                                    <div className="crypto-form">
                                        <div className="tab-buttons">
                                            <div className="tab-button active" id="buy-tab">Buy</div>
                                            <div className="tab-button" id="sell-tab">Sell</div>
                                        </div>
                                        <div id="buy-form" className="crypto-form-content active">
                                            <form>
                                                <div className="mb-4">
                                                    <label for="spend" className="form-label">Spend</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" id="spend"
                                                            placeholder="61 - 68,456" />
                                                        <select className="form-select">
                                                            <option>AED</option>
                                                            <option>USD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <label for="receive" className="form-label">Receive</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" id="receive"
                                                            placeholder="0" />
                                                        <select className="form-select">
                                                            <option>USDT</option>
                                                            <option>BTC</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-buy w-100">Buy</button>
                                            </form>
                                        </div>
                                        <div id="sell-form" className="crypto-form-content">
                                            <form>
                                                <div className="mb-4">
                                                    <label for="sell-amount" className="form-label">Sell</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" id="sell-amount"
                                                            placeholder="0" />
                                                        <select className="form-select">
                                                            <option>USDT</option>
                                                            <option>BTC</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <label for="receive-sell" className="form-label">Receive</label>
                                                    <div className="input-group">
                                                        <input type="number" className="form-control" id="receive-sell"
                                                            placeholder="61 - 68,456" />
                                                        <select className="form-select">
                                                            <option>AED</option>
                                                            <option>USD</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-buy w-100">Sell</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container1 my-5">
                                <h1 className="text-start btm-title-bs">How to Buy Crypto</h1>
                                <div className="row gy-4">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="custom-card p-1 position-relative">
                                            <h1 className="step-number">1</h1>
                                            <h5 className="fw-bold">Enter Amount &amp; Select Payment</h5>
                                            <p className="text-muted">Enter the amount, select the available payment method,
                                                and choose the payment account or bind the payment card.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="custom-card p-1 position-relative">
                                            <h1 className="step-number">2</h1>
                                            <h5 className="fw-bold">Confirm Order</h5>
                                            <p className="text-muted">Confirmation of transaction detail information,
                                                including trading pair quotes, fees, and other explanatory tips.</p>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="custom-card p-1 position-relative">
                                            <h1 className="step-number">3</h1>
                                            <h5 className="fw-bold">Receive Crypto</h5>
                                            <p className="text-muted">After successful payment, the purchased crypto will be
                                                deposited into your Spot or Funding Wallet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container1 my-5">
                                <h1 className="text-start btm-title-bs">Top Cryptocurrencies Available on Bitx
                                    <a className="view-btn" href="#">View More &gt;</a>
                                </h1>
                                <div className="card-container">

                                    <div className="card-box"
                                    >
                                        <img src="https://cryptologos.cc/logos/tether-usdt-logo.png"
                                            className="img-fluid" alt="Tether" />
                                        <div className="card-body">
                                            <h5 className="card-title">USDT</h5>
                                            <p className="card-text">$1.00</p>
                                        </div>
                                    </div>

                                    <div className="card-box"
                                    >
                                        <img src="https://cryptologos.cc/logos/usd-coin-usdc-logo.png"
                                            className="img-fluid" alt="USD Coin" />
                                        <div className="card-body">
                                            <h5 className="card-title">USDC</h5>
                                            <p className="card-text">$1.00</p>
                                        </div>
                                    </div>

                                    <div className="card-box"
                                    >
                                        <img src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
                                            className="img-fluid" alt="Bitcoin" />
                                        <div className="card-body">
                                            <h5 className="card-title">BTC</h5>
                                            <p className="card-text">$57,941.27</p>
                                        </div>
                                    </div>

                                    <div className="card-box"
                                    >
                                        <img src="https://cryptologos.cc/logos/binance-coin-bnb-logo.png"
                                            className="img-fluid" alt="Binance Coin" />
                                        <div className="card-body">
                                            <h5 className="card-title">BNB</h5>
                                            <p className="card-text">$539.10</p>
                                        </div>
                                    </div>

                                    <div className="card-box"
                                    >
                                        <img src="https://cryptologos.cc/logos/ethereum-eth-logo.png"
                                            className="img-fluid" alt="Ethereum" />
                                        <div className="card-body">
                                            <h5 className="card-title">ETH</h5>
                                            <p className="card-text">$2,349.90</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel header="Deposit">
                            <div className="deposit-form">

                                <div className="row">
                                    <div className="col-sm-5">
                                        <div className="form-section-main">
                                            <h1>Deposit</h1>
                                            <div className="form-section">
                                                <div className="form-group">
                                                    <select id="select-network" className="form-control">
                                                        <option selected="">AED United Arab Emirates</option>
                                                        <option value="1">AED United Arab Emirates</option>
                                                        <option value="2">AED United Arab Emirates</option>
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label>Deposit with Fiat</label>
                                                    <div className="select-box">
                                                        <div>
                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
                                                                alt="Visa" />
                                                            <img src="https://upload.wikimedia.org/wikipedia/commons/0/04/Mastercard-logo.png"
                                                                alt="MasterCard" />
                                                            <span>Visa/MasterCard</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-section">
                                                <h3>Deposit with Crypto</h3>
                                                <div className="crypto-select">
                                                    <span>Bitcoin</span>
                                                    <span>$57,900</span>
                                                </div>
                                            </div>
                                            <div className="button-container">
                                                <button className="btn-style w-100" onclick="openModal()">Continue</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 offset-sm-3">
                                        <div className="how-it-works">
                                            <h3>How it works?</h3>
                                            <div className="steps">
                                                <div>
                                                    <div className="step-number-how">1</div>
                                                    <span >Complete
                                                        Advanced KYC</span>
                                                </div>
                                                <div>
                                                    <div className="step-number-how disabled">2</div>
                                                    <span className="disabled" >Pay
                                                        with Debit/Credit Card</span>
                                                </div>
                                                <div>
                                                    <div className="step-number-how disabled">3</div>
                                                    <span className="disabled">Transaction Completed</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </TabPanel>

                        <TabPanel header="Withdraw">
                            <div className="row">
                                <div className="col-md-6 mx-auto">

                                    <div class="form-section">
                                        <h1>Withdraw</h1>
                                        <div class="form-group">
                                            <label for="select-coin">Select Coin</label>
                                            <div class="crypto-select">
                                                <img src="https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg"
                                                    alt="Bitcoin Logo" />
                                                <span>BTC Bitcoin</span>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="withdraw-to">Withdraw To</label>
                                            <input type="text" id="withdraw-to" class="form-control"
                                                placeholder="Enter Address" />
                                        </div>
                                        <div class="form-group">
                                            <label for="withdraw-to">Deposit with Crypto</label>
                                            <select id="select-network" class="form-control"
                                            >
                                                <option selected="">Select Network</option>
                                                <option value="1">Bitcoin</option>
                                                <option value="2">Solana</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="withdraw-amount">Withdrawal Amount</label>
                                            <input type="text" id="withdraw-amount" class="form-control"
                                                placeholder="Minimum 0" />

                                        </div>
                                        <div class="withdraw-info">
                                            <p>Available: <span className="float-end">0.0001234 Bitcoin</span></p>
                                            <p>Receive amount: <strong className="float-end">0.0006 Bitcoin</strong></p>
                                            <p>Network fee: 0.000000008 BTC</p>
                                        </div>
                                        <button class="btn-style w-100" onclick="handleWithdraw()">Withdraw</button>
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
            {/* Modal */}
            <Dialog
                visible={isModalVisible}
                onHide={closeModal}
                header="Deposit BTC"
                style={{ width: "50vw" }}
            >
                <div>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/800px-QR_code_for_mobile_English_Wikipedia.svg.png"
                        alt="QR Code"
                        width="200"
                    />
                    <p>Network: Bitcoin</p>
                    <p>Address: 1qxy2kdgyjrstqqz2n0ryf24393b83kkjfx0vlih</p>
                </div>
            </Dialog>
        </div>
    );
};

export default BuySell;
