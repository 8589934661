import axios from "axios";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apisService } from "../../services/apiService";

const Marketing = () => {

    const [selectedCategoryId, setSelectedCategoryId] = useState('1');
    const [filteredCryptoData, setFilteredCryptoData] = useState([]);
    const [apiData, setApiData] = useState([])
    const [allCoins, setAllCoins] = useState([])
    const [userName, setUserName] = useState('')
    const [userId, setUserId] = useState('')
    const [activeCurrency, setActiveCurrency] = useState("USDT");
    const [activeCurrencyAllCoins, setActiveCurrencyAllCoins] = useState("USDT");
    const currencies = ["USDT", "FDUST", "TUSD", "BNB", "BTC"];
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTokenAndCallAPI = () => {
            localStorage.getItem('ACCESS_TOKEN');
        };
        const fetchInitialData = async () => {
            await fetchTokenAndCallAPI();
            // Fetch daily stats initially
            setSelectedCategoryId("1")
            await getDailyStats();
        };
        fetchInitialData();
    }, []);
    useEffect(() => {
        let categoryData = [];
        switch (selectedCategoryId) {
            case '1':
                categoryData = allCoins;
                break;
            case '2':
                categoryData = apiData.hotGainerData;
                break;
            case '3':
                categoryData = apiData.topGainerData;
                break;
            case '4':
                categoryData = apiData.highVolumeData;
                break;
            case '5':
                categoryData = apiData.newListingData;
                break;
            default:
                categoryData = [];
        }
        setFilteredCryptoData(categoryData);
    }, [selectedCategoryId]);

    const statFiletrs = (apiData) => {
        let categoryData = [];
        switch (selectedCategoryId) {
            case '1':
                categoryData = allCoins;
                break;
            case '2':
                categoryData = apiData.hotGainerData;
                break;
            case '3':
                categoryData = apiData.topGainerData;
                break;
            case '4':
                categoryData = apiData.highVolumeData;
                break;
            case '5':
                categoryData = apiData.newListingData;
                break;
            default:
                categoryData = [];
        }
        setFilteredCryptoData(categoryData);
    }
    const statFiletrsAllCoins = (apiData) => {
        let categoryData = [];
        switch (selectedCategoryId) {
            case '1':
                categoryData = apiData;
                break;
            default:
                categoryData = [];
        }
        setFilteredCryptoData(categoryData);
    }

    const handleCurrencyClick = async (currency) => {
        setActiveCurrency(currency);
        try {
            let token = localStorage.getItem("ACCESS_TOKEN");
            if (!token) {
                throw new Error('No token found');
            }
            const response = await axios.get(
                `https://backend.bitxuae.com/binance/daily-stats?pairSymbol=${currency}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setApiData(response.data);
            console.log(response.data, "binance get");
            statFiletrs(response?.data);
        } catch (err) {
            console.error('Error fetching data:', err);
        }

    };
    const handleCurrencyClickAll = async (currency) => {
        setActiveCurrencyAllCoins(currency);
        try {
            let token = localStorage.getItem("ACCESS_TOKEN");
            if (!token) {
                throw new Error('No token found');
            }
            const response = await axios.get(
                `https://backend.bitxuae.com/binance/daily-stats-all-coins?pairSymbol=${currency}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setAllCoins(response.data);
            // console.log(response.data, "binance get");
            // statFiletrs(response?.data);
        } catch (err) {
            console.error('Error fetching data:', err);
        }

    };

    const formatNumber = (num) => parseFloat(num).toFixed(2);
    const formatPrice = (price) => `$${formatNumber(price)}`;
    const formatPercentage = (percentage) => `${formatNumber(percentage)}%`;

    const getDailyStats = async () => {
        let token = localStorage.getItem("ACCESS_TOKEN");
        if (!token) {
            throw new Error('No token found');
        }
        try {

            const response = await axios.get(
                `https://backend.bitxuae.com/binance/daily-stats?pairSymbol=${activeCurrency}`,
                // `http://ec2-13-215-249-1.ap-southeast-1.compute.amazonaws.com:3000/binance/daily-stats?pairSymbol=${activeCurrency}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setApiData(response.data);
            console.log(response.data, "binance get");
            statFiletrs(response?.data);
        } catch (err) {
            console.error('Error fetching data:', err);
        }
        try {

            const allCoinsRes = await axios.get(
                `https://backend.bitxuae.com/binance/daily-stats-all-coins?pairSymbol=${activeCurrency}`,
                // `http://ec2-13-215-249-1.ap-southeast-1.compute.amazonaws.com:3000/binance/daily-stats?pairSymbol=${activeCurrency}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setFilteredCryptoData(allCoinsRes?.data)
            setAllCoins(allCoinsRes.data);
            statFiletrsAllCoins(allCoinsRes?.data);
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }

    const handleTabChange = (e) => {
        const newCategoryId = (e.index + 1).toString();
        setSelectedCategoryId(newCategoryId);
    };
    const onRowClick = (rowData) => {
        navigate(`/crypto`, { state: { symbol: rowData.symbol } });
    };

    const lastPriceTemplate = (item) => {
        return formatNumber(item.lastPrice)
    }

    const prevPriceTemplate = (item) => {
        return formatPrice(item.prevClosePrice)
    }

    const priceChangePercentTemplate = (item) => {
        return <button className={item.priceChangePercent < 0 ? "negative-price" : 'positive-price'}> {formatPercentage(item.priceChangePercent)}</button>
    }

    return (
        <TabView activeIndex={parseInt(selectedCategoryId) - 1} onTabChange={handleTabChange}>
            {/* Hot Tab */}
            <TabPanel header="All">
                <div>
                    {/* Horizontal list */}
                    <div className='horizontal-list'>
                        {currencies.map((currency) => (
                            <div
                                key={currency}
                                onClick={() => handleCurrencyClickAll(currency)}
                                style={{
                                    margin: "0 10px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: 5,
                                    background: activeCurrencyAllCoins === currency ? "#e2ecf4" : "",
                                    fontWeight: activeCurrencyAllCoins === currency ? "bold" : "normal",
                                    color: activeCurrencyAllCoins === currency ? "#007ad9" : "black",
                                    // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                                }}
                            >
                                {currency}
                            </div>
                        ))}
                    </div>
                    <DataTable
                        value={allCoins}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)} // Add onRowClick for navigation
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}

                    >
                        <Column
                            header="Name"
                            body={(rowData) => (
                                <div>
                                    <img
                                        src={rowData.logo}
                                        alt={rowData.name}
                                        style={{ width: "24px", marginRight: "8px" }}
                                    />
                                    {rowData?.symbol}
                                </div>
                            )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                        {/* <Column header="Action" body={actionTemplate} /> */}
                    </DataTable>
                </div>
            </TabPanel>
            <TabPanel header="Hot">
                <div>
                    {/* Horizontal list */}
                    <div className='horizontal-list'>
                        {currencies.map((currency) => (
                            <div
                                key={currency}
                                onClick={() => handleCurrencyClick(currency)}
                                style={{
                                    margin: "0 10px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: 5,
                                    background: activeCurrency === currency ? "#e2ecf4" : "",
                                    fontWeight: activeCurrency === currency ? "bold" : "normal",
                                    color: activeCurrency === currency ? "#007ad9" : "black",
                                    // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                                }}
                            >
                                {currency}
                            </div>
                        ))}
                    </div>
                    <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)} // Add onRowClick for navigation
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}

                    >
                        <Column
                            header="Name"
                            body={(rowData) => (
                                <div>
                                    <img
                                        src={rowData.logo}
                                        alt={rowData.name}
                                        style={{ width: "24px", marginRight: "8px" }}
                                    />
                                    {rowData?.symbol}
                                </div>
                            )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                        {/* <Column header="Action" body={actionTemplate} /> */}
                    </DataTable>
                </div>
            </TabPanel>

            {/* Top Gainer Tab */}
            <TabPanel header="Top Gainer">
                <div>
                    {/* Horizontal list */}
                    <div className='horizontal-list'>
                        {currencies.map((currency) => (
                            <div
                                key={currency}
                                onClick={() => handleCurrencyClick(currency)}
                                style={{
                                    margin: "0 10px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: 5,
                                    background: activeCurrency === currency ? "#e2ecf4" : "",
                                    fontWeight: activeCurrency === currency ? "bold" : "normal",
                                    color: activeCurrency === currency ? "#007ad9" : "black",
                                    // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                                }}
                            >
                                {currency}
                            </div>
                        ))}
                    </div>
                    <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)}
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}
                    >
                        <Column
                            header="Name"
                            body={(rowData) => (
                                <div>
                                    <img
                                        src={rowData.logo}
                                        alt={rowData.name}
                                        style={{ width: "24px", marginRight: "8px" }}
                                    />
                                    {rowData.symbol}
                                </div>
                            )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                    </DataTable>
                </div>
            </TabPanel>

            {/* High Volume Tab */}
            <TabPanel header="High Volume">
                <div>
                    {/* Horizontal list */}
                    <div className='horizontal-list'>
                        {currencies.map((currency) => (
                            <div
                                key={currency}
                                onClick={() => handleCurrencyClick(currency)}
                                style={{
                                    margin: "0 10px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: 5,
                                    background: activeCurrency === currency ? "#e2ecf4" : "",
                                    fontWeight: activeCurrency === currency ? "bold" : "normal",
                                    color: activeCurrency === currency ? "#007ad9" : "black",
                                    // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                                }}
                            >
                                {currency}
                            </div>
                        ))}
                    </div>
                    <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)}
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}
                    >
                        <Column
                            header="Name"
                            body={(rowData) => (
                                <div>
                                    <img
                                        src={rowData.logo}
                                        alt={rowData.name}
                                        style={{ width: "24px", marginRight: "8px" }}
                                    />
                                    {rowData.symbol}
                                </div>
                            )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                    </DataTable>
                </div>
            </TabPanel>

            {/* New Listing Tab */}
            <TabPanel header="New Listing">
                <div>
                    {/* Horizontal list */}
                    <div className='horizontal-list'>
                        {currencies.map((currency) => (
                            <div
                                key={currency}
                                onClick={() => handleCurrencyClick(currency)}
                                style={{
                                    margin: "0 10px",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                    borderRadius: 5,
                                    background: activeCurrency === currency ? "#e2ecf4" : "",
                                    fontWeight: activeCurrency === currency ? "bold" : "normal",
                                    color: activeCurrency === currency ? "#007ad9" : "black",
                                    // borderBottom: activeCurrency === currency ? "2px solid #007ad9" : "none",
                                }}
                            >
                                {currency}
                            </div>
                        ))}
                    </div>
                    <DataTable
                        value={filteredCryptoData}
                        stripedRows
                        scrollable
                        scrollHeight="70vh"
                        rowClassName={() => "hoverable-row"} // Add a class for each row
                        onRowClick={(e) => onRowClick(e.data)}
                        globalFilterFields={['symbol']}
                        globalFilter={globalFilterValue}
                    >
                        <Column
                            header="Name"
                            body={(rowData) => (
                                <div>
                                    <img
                                        src={rowData.logo}
                                        alt={rowData.name}
                                        style={{ width: "24px", marginRight: "8px" }}
                                    />
                                    {rowData.symbol}
                                </div>
                            )}
                        />
                        <Column header="Last Price" field="lastPrice" body={lastPriceTemplate} />
                        <Column header="Previous Price" field="prevClosePrice" body={prevPriceTemplate} />
                        <Column header="24h chg%" field="priceChangePercent" body={priceChangePercentTemplate} />
                    </DataTable>
                </div>
            </TabPanel>
        </TabView>
    )
}
export default Marketing