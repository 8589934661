import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import { apisService } from '../../services/apiService';
import siteLogo from '../../assets/images/logo.png';
import './signupStyles.css';

const Signup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const toast = React.useRef(null);

    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // Validate email
    const isValidEmail = (email) => emailRegex.test(email);

    // Handle "Next" button click
    const handleNextClick = async () => {
        if (!email) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter your email.', life: 3000 });
            return;
        }

        if (!isValidEmail(email)) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please enter a valid email address.', life: 3000 });
            return;
        }

        if (!isChecked) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'You must agree to the Terms.', life: 3000 });
            return;
        }

        setLoading(true);

        const payload = {
            email: email,
            type: 'signup',
        };

        try {
            const response = await apisService.signupApi(payload);

            if (response?.message === 'Registration successful. Please verify your email to sign in.') {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Signup successful. Redirecting...', life: 3000 });
                navigate('/verifyotp', { state: { email } }); // Navigate to the next page with email
            } else {
                throw new Error(response?.message || 'Something went wrong');
            }
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error?.response?.data?.message || error.message,
                life: 3000,
            });
        } finally {
            // navigate('/verifyotp', { state: { email } }); // Navigate to the next page with email
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            {/* Toast for notifications */}
            <Toast ref={toast} />

            {/* Header with logo */}
            <div className="header-logo">
                <img src={siteLogo} alt="Bitx Logo" />
            </div>

            {/* Title */}
            <h2>Welcome to Bitx</h2>
            {/* Signup Form */}
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <InputText
                        id="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className="checkbox">
                    <Checkbox
                        inputId="terms"
                        checked={isChecked}
                        onChange={(e) => setIsChecked(e.checked)}
                    />
                    <label htmlFor="terms">
                        By creating an account, I agree to Bitx <Link to="/terms">Terms</Link>.
                    </label>
                </div>

                {/* Next Button */}
                <Button
                    label="Next"
                    className="btn-login"
                    onClick={handleNextClick}
                    loading={loading}
                />

                {/* Divider */}
                <p className="text-center" style={{ margin: '15px 0' }}>or</p>

                {/* Social Buttons */}
                <Button className="btn-social btn-google">
                    <i className="fa fa-google"></i> Continue with Google
                </Button>
                <Button className="btn-social btn-apple">
                    <i className="fa fa-apple"></i> Continue with Apple
                </Button>
            </form>

            {/* Login Link */}
            <p className="text-center" style={{ marginTop: '15px' }}>
                Already have an account? <Link to="/login">Log in</Link>
            </p>
        </div>
    );
};

export default Signup;
