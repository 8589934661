import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { apisService } from '../../services/apiService';
import './signupStyles.css';

const CreatePassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const toast = React.useRef(null);

    const { email, xToken } = location.state || {}; // Get email and xToken from route state
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
    };

    const validatePassword = (password) => {
        const minLength = 10;
        const maxLength = 32;
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /[0-9]/.test(password);
        const noSpaces = !/\s/.test(password);
        const validLength = password.length >= minLength && password.length <= maxLength;

        if (!validLength) return `Password must be between ${minLength} and ${maxLength} characters long.`;
        if (!hasUppercase) return 'Password must contain at least one uppercase letter.';
        if (!hasLowercase) return 'Password must contain at least one lowercase letter.';
        if (!hasNumber) return 'Password must contain at least one number.';
        if (!noSpaces) return 'Password must not contain any spaces.';

        return null; // No errors
    };

    const handleConfirmClick = async () => {
        const validationError = validatePassword(password);

        if (validationError) {
            toast.current.show({ severity: 'error', summary: 'Invalid Password', detail: validationError, life: 3000 });
            return;
        }

        setLoading(true);

        const payload = { email, password };

        try {
            const response = await apisService.setPasswordApi(payload, xToken);

            toast.current.show({ severity: 'success', summary: 'Success', detail: response?.message, life: 3000 });
            setIsDialogVisible(true); // Show dialog on success
        } catch (error) {
            console.error('Error during password creation:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error?.response?.data?.message || 'Password creation failed.',
                life: 3000,
            });
        } finally {
            setLoading(false);
            // setIsDialogVisible(true); // Show dialog on success
        }
    };

    const handleVerifyClick = () => {
        setIsDialogVisible(false);
        navigate('/kycAuthOne'); // Navigate to the next step
    };

    const handleVerifyLaterClick = () => {
        setIsDialogVisible(false);
        navigate('/login'); // Navigate back to login
    };

    return (
        <div className="password-container">
            <Toast ref={toast} />

            <div className="back-arrow">
                <Link to="/verifyOTP">
                    <i className="fa fa-arrow-left"></i>
                </Link>
            </div>

            <h2>Create Your Password</h2>
            <p className="subtitle">We're almost there!</p>

            <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="password-input-container">
                    <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        id="password"
                        className="form-control"
                        placeholder="Enter Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <i
                        style={{ position: 'absolute', top: '52%', right: 51, cursor: 'pointer' }}
                        className={`fa ${isPasswordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                        onClick={togglePasswordVisibility}
                    ></i>
                </div>
            </div>

            <div className="password-requirements">
                <ul>
                    <li>10-32 characters.</li>
                    <li>At least one uppercase, lowercase, and number.</li>
                    <li>Does not contain any spaces.</li>
                </ul>
            </div>

            <button
                type="button"
                className="btn btn-confirm"
                onClick={handleConfirmClick}
                disabled={loading}
            >
                {loading ? 'Processing...' : 'Confirm'}
            </button>

            <Dialog
                visible={isDialogVisible}
                onHide={() => setIsDialogVisible(false)}
                className="dialog-content"

            >
                <div className="modal-header">
                    <i className="fa fa-check-circle icon-check"></i>
                </div>
                <div className="modal-body">
                    <h4>What's next?</h4>
                    <p>
                        Verify your account to enable deposits, crypto purchases, and trading. While
                        you're at it, unlock up to 8,200 USDT in new-user rewards!
                    </p>
                    <button className="btn-verify" onClick={handleVerifyClick}>
                        Verify Now
                    </button>
                    <button
                        className="btn-verify btn-verify-later"
                        onClick={handleVerifyLaterClick}
                        style={{ marginTop: '10px' }}
                    >
                        Verify Later
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export default CreatePassword;
